import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  default as FormattedDate,
  dayFormatId,
  hourFormatId,
} from "./formatted-date"

import "./calendar.css"

const monthDuration = 31 * 24 * 60 * 60 * 1000

const Calendar = () => {
  const data = useStaticQuery(graphql`
    query CalendarQuery {
      allSportEvents {
        nodes {
          teamId
          events {
            competition
            date
            detail
            team1
            team2
          }
        }
      }
    }
  `)

  const allEvents = data.allSportEvents.nodes.reduce(
    (allEvents, { events }) => {
      for (const event of events) {
        const { team1, team2, date } = event
        const key = `${team1}:${team2}:${date}`
        allEvents.set(key, event)
      }
      return allEvents
    },
    new Map()
  )

  const sorted = Array.from(allEvents.values()).sort(
    ({ date: dateA }, { date: dateB }) =>
      new Date(dateA).getTime() - new Date(dateB).getTime()
  )

  const now = Date.now()
  const nextMonth = now + monthDuration

  return (
    <table className="calendar__table">
      <tbody>
        {sorted.map(({ date, team1, team2, competition, detail }) => {
          const key = `${team1}:${team2}:${date}`
          const timestamp = new Date(date).getTime()

          // only future dates
          if (timestamp < now) {
            return null
          }

          // but less than a month from now
          if (timestamp > nextMonth) {
            return null
          }

          return (
            <tr className="calendar__event" key={key}>
              <td className="calendar__date">
                <FormattedDate date={timestamp} format={dayFormatId} />
                <br />
                <FormattedDate date={timestamp} format={hourFormatId} />
              </td>
              <td className="calendar__team1">{team1}</td>
              <td className="calendar__team2">{team2}</td>
              <td className="calendar__info">
                {competition}
                <br />
                <span className="calendar__detail">{detail}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Calendar
