import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Calendar from "../components/calendar"

const IndexPage = () => (
  <Layout>
    <SEO title="Calendrier" />
    <h1>Calendrier</h1>
    <Calendar />
  </Layout>
)

export default IndexPage
