import PropTypes from "prop-types"

export const dayFormatId = "day"
export const hourFormatId = "hour"

const dayDuration = 24 * 60 * 60 * 1000
const weekdays = ["di", "lu", "ma", "me", "je", "ve", "sa"]
const months = [
  "janv.",
  "fév.",
  "mars",
  "avr.",
  "mai",
  "juin",
  "juil.",
  "août",
  "sept.",
  "oct.",
  "nov.",
  "déc.",
]
const todayLabel = "auj."
const tomorrowLabel = "demain"

const twoDigits = n => (n < 10 ? `0${n}` : n)

const getDay = date => weekdays[date.getDay()]
const getMonth = date => months[date.getMonth()]

function FormattedDate({ date, format }) {
  date = new Date(date)

  if (format === dayFormatId) {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    // today
    if (date.getTime() < today.getTime() + dayDuration) {
      return todayLabel
    }

    // tomorrow
    if (date.getTime() < today.getTime() + 2 * dayDuration) {
      return tomorrowLabel
    }

    // default
    return `${getDay(date)} ${twoDigits(date.getDate())} ${getMonth(date)}`
  }

  if (format === hourFormatId) {
    return `${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`
  }

  return null
}

FormattedDate.defaultProps = {
  format: dayFormatId,
}

FormattedDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  format: PropTypes.string,
}

export default FormattedDate
